























































































































/* eslint-disable */
import {useActions, useState} from '@u3u/vue-hooks'
import {defineComponent, onBeforeMount} from '@vue/composition-api'
import InformationBlockProfile from '@/components/my/dossier/new-components/InformationBlockProfile.vue'

export default defineComponent({
  name: 'my-record',
  components: {InformationBlockProfile},
  setup(_props, ctx) {
    const {refreshDossier} = useActions('user', ['refreshDossier'])
    const {user, chrome} = useState(['user', 'chrome'])
    const {i18n, i18nSuivi} = useState('my', ['i18n', 'i18nSuivi'])
    const stepAction = ['0', '21', '32', '42', '44', '61', '72', '81', '84']

    onBeforeMount(async () => {
      user.value.dossiers.sort((a, b) => {
        if (
            stepAction.includes(a.codeStatut) ===
            stepAction.includes(b.codeStatut)
        ) {
          // trier par code statut
          if (a.codeStatut - b.codeStatut > 0) {
            return 1
          } else if (a.codeStatut - b.codeStatut === 0) {
            return 0
          } else {
            return -1
          }
        } else {
          if (!stepAction.includes(a.codeStatut)) {
            return 1
          } else {
            return -1
          }
        }
      })
      refreshDossier()
    })

    const isFinished = dossier => {
      if (!stepAction.includes(dossier.codeStatut)) {
        return true
      } else {
        return false
      }
    }
    const actionRealiser = etape => {
      if (etape === '91') {
        return i18nSuivi.value.delFacture
      }
      if (stepAction.includes(etape)) {
        return i18nSuivi.value.actionRealized
      } else {
        return i18nSuivi.value.checkRecord
      }
    }
    const isProgress = dossier => {
      if (stepAction.includes(dossier.codeStatut)) {
        return true
      } else {
        return false
      }
    }
    const isChecked = (cpt, dossier) => {
      if (cpt <= dossier.numEtape || dossier.numEtape === '9') {
        return true
      } else {
        return false
      }
    }
    const isClosed = dossier => {
      if (dossier.numEtape === '9') {
        return true
      }
      return false
    }

    return {
      chromeI18n: chrome.value.data.i18n,
      i18n,
      i18nSuivi,
      numberSteps: 9,
      user,
      isFinished,
      isProgress,
      isChecked,
      isClosed,
      actionRealiser,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})








































import { useState } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'information-block-profile',
  components: {},
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    /* eslint-disable */
    const {i18n} = useState('my', ['i18n'])

    return {
      i18n,
    }
  },
})





































































/* eslint-disable */
import { useActions, useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { TimelineHorizontal } from '@/inc/types'
import Axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

const HorizontalTimeline = () => import('@/components/HorizontalTimeline.vue')
const StepTwo = () => import('@/components/my/dossier/StepTwo.vue')
const StepOne = () => import('@/components/my/dossier/StepOne.vue')
const StepThree = () => import('@/components/my/dossier/StepThree.vue')
const StepFour = () => import('@/components/my/dossier/StepFour.vue')
const StepFive = () => import('@/components/my/dossier/StepFive.vue')
const StepSix = () => import('@/components/my/dossier/StepSix.vue')
const StepSeven = () => import('@/components/my/dossier/StepSeven.vue')
const StepEight = () => import('@/components/my/dossier/StepEight.vue')
const StepNine = () => import('@/components/my/dossier/StepNine.vue')

export default defineComponent({
  name: 'my-record-single',
  components: {
    HorizontalTimeline,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    StepSeven,
    StepEight,
    StepNine,
  },

  setup(props, ctx) {
    const { $route } = ctx.root
    const { dossierId } = $route.params
    const { user, chrome } = useState(['user', 'chrome'])
    const { i18n, i18nSuivi } = useState('my', ['i18n', 'i18nSuivi'])
    const { refreshDossier } = useActions('user', ['refreshDossier'])

    const ONE = ref('1')
    const TWO = ref('2')
    const THREE = ref('3')
    const FOUR = ref('4')
    const FIVE = ref('5')
    const SIX = ref('6')
    const SEVEN = ref('7')
    const EIGHT = ref('8')
    const NINE = ref('9')

    const debug = ref()
    const urlIdRefresh = ref(false)

    const record = id => {
      Axios.post(`${getApiUrl()}/works/records`, {
        NumDossier: [id],
      })
        .then(resp => {
          debug.value = resp.data.liste[0]
          if (!user.value.dossiers.includes(dossierId)) {
            urlIdRefresh.value = false
          }
        })
        .catch(e => {
          urlIdRefresh.value = true
        })
    }
    onBeforeMount(async () => {
      //refreshDossier()
      // si une personne essaie d acceder à son dossier par l'url, on recupere les sous dossiers du user
      if (debug.value === undefined) {
        if (
          !(user.value.dossiers.find(x => x.id === dossierId) !== undefined)
        ) {
          urlIdRefresh.value = true
        } else {
          await record(dossierId)
        }
      }
    })
    const redirect = () => {
      refreshDossier()
      ctx.root.$router.push({ name: 'MyRecord' })
    }

    return {
      dossierId,
      chromeI18n: chrome.value.data.i18n,
      i18n,
      user,
      debug,
      i18nSuivi,
      ONE,
      TWO,
      THREE,
      FOUR,
      FIVE,
      SIX,
      SEVEN,
      EIGHT,
      NINE,
      redirect,
      urlIdRefresh,
    }
  },
})
